import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { Container, Row, Col, Collapse, FormGroup } from 'reactstrap'
import Slider from 'react-slick'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Img from 'gatsby-image/withIEPolyfill'

import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'

class SchoolsIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const pageTitle = get(this, 'props.data.contentfulSchools.title')

    const header = get(this, 'props.data.contentfulSchools.header')
    const headerMobile = get(this, 'props.data.contentfulSchools.mobileHeader')
    const topContent = get(this, 'props.data.contentfulSchools.topContent')

    const game = get(this, 'props.data.contentfulSchools.game')
    const toolkit = get(this, 'props.data.contentfulSchools.toolKit')

    const tips = get(this, 'props.data.contentfulSchools.tips')

    const metaDescription = get(
      this,
      'props.data.contentfulSchools.metaDescription'
    )
    const metaImageURL = get(
      this,
      'props.data.contentfulSchools.metaImage.file.url'
    )

    const SlickButtonFix = ({
      currentSlide,
      slideCount,
      children,
      ...props
    }) => <span {...props}>{children}</span>

    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      prevArrow: (
        <SlickButtonFix>
          <button type="button" aria-label="Previous">
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
        </SlickButtonFix>
      ),
      nextArrow: (
        <SlickButtonFix>
          <button type="button" aria-label="Next">
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </SlickButtonFix>
      ),
    }

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={pageTitle + ' | ' + siteTitle}>
            <meta name="description" content={metaDescription} />
            <meta
              property="og:url"
              content={'https://cleanairgm.com/schools'}
            />
            <meta property="og:type" content={'website'} />
            <meta property="og:title" content={pageTitle + ' | ' + siteTitle} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:image" content={metaImageURL} />
          </Helmet>
          <div className="page-banner">
            <Img
              fluid={header.fluid}
              className="page-banner__img d-none d-lg-block"
            />
            <Img
              fluid={headerMobile.fluid}
              className="page-banner__img d-block d-lg-none"
            />
            <h1>{pageTitle}</h1>
          </div>
          <Container className="mb-5 mt-5 text-center text-lg-left">
            <Row>
              <Col className="mb-4 mb-md-0 vc" sm={12} lg={{ size: 6 }}>
                <Img className="br-10" fluid={topContent.image.fluid} />
              </Col>
              <Col
                xs={{ order: 1 }}
                sm={12}
                lg={{ order: 0, size: 6 }}
                className="vc"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: topContent.text.childMarkdownRemark.html,
                  }}
                ></div>
              </Col>
            </Row>
          </Container>
          <Container className="mb-5 mt-5 text-center text-lg-left">
            <Row>
              <Col sm={12} lg={{ size: 6 }} className="vc">
                <h2 className="h2-potential mb-3">{toolkit.title}</h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: toolkit.text.childMarkdownRemark.html,
                  }}
                ></div>
                <Link className="btn btn-call w-50" to={'/schools/toolkit'}>
                  Register to download toolkit
                </Link>
              </Col>
              <Col className="mb-4 mb-md-0 vc" sm={12} lg={{ size: 6 }}>
                <Img className="br-10" fluid={toolkit.image.fluid} />
              </Col>
            </Row>
          </Container>
          <div className="tips tips--drivers">
            <Container>
              <h2>{tips.title}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: tips.description.childMarkdownRemark.html,
                }}
              ></div>
            </Container>
            <Slider {...settings}>
              {tips.tips.map((t, i) => (
                <div key={`d${i}`} className="tip-slide-container">
                  <div className={`tip-slide tip-slide--s${i % 8}`}>
                    <Img
                      loading="eager"
                      objectFit="contain"
                      objectPosition="50% 50%"
                      className={`tip-slide__img`}
                      fluid={t.image.fluid}
                    />
                    <h2>{t.title}</h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t.description.childMarkdownRemark.html,
                      }}
                    ></div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          {/* <Container className="mb-5 mt-5 text-center text-lg-left">
            <Row>
              <Col className="mb-4 mb-md-0 vc" sm={12} lg={{ size: 6 }}>
                <Img className="br-10" fluid={game.image.fluid} />
              </Col>

              <Col sm={12} lg={{ size: 6 }} className="vc">
                <h2 className="h2-potential mb-3">{game.title}</h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: game.text.childMarkdownRemark.html,
                  }}
                ></div>
                <div>
                  <a
                    className="btn btn-content"
                    href="https://www.cleanairgame.com"
                  >
                    Play now
                  </a>
                </div>
              </Col>
            </Row>
          </Container> */}
        </div>
      </Layout>
    )
  }
}

export default SchoolsIndex

export const pageQuery = graphql`
  query SchoolsQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentfulSchools {
      title
      metaDescription
      metaImage {
        file {
          url
        }
      }
      header {
        fluid(maxWidth: 1920, maxHeight: 450, quality: 95) {
          ...GatsbyContentfulFluid
        }
      }
      mobileHeader {
        fluid(maxWidth: 1080, maxHeight: 720) {
          ...GatsbyContentfulFluid
        }
      }
      topContent {
        text {
          childMarkdownRemark {
            html
          }
        }
        image {
          fluid(maxWidth: 670, maxHeight: 400) {
            ...GatsbyContentfulFluid
          }
        }
      }
      game {
        title
        text {
          childMarkdownRemark {
            html
          }
        }
        image {
          fluid(maxWidth: 670, maxHeight: 400) {
            ...GatsbyContentfulFluid
          }
        }
      }
      toolKit {
        title
        text {
          childMarkdownRemark {
            html
          }
        }
        image {
          fluid(maxWidth: 670, maxHeight: 400) {
            ...GatsbyContentfulFluid
          }
        }
      }
      tips {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        tips {
          title
          description {
            childMarkdownRemark {
              html
            }
          }
          image {
            fluid(maxWidth: 390, maxHeight: 390) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
